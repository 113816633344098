.row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mintImg {
  border: black 1px solid;
  border-radius: 20px;
  box-shadow: 10px 5px 5px grey;
  width: 100px;
  height: 100px;
  display: block;
  margin-bottom: 10px;
}
.mintLi {
  width: 100px;
  display: inline-block;
  margin: 10px;
}
.mintUl {
  margin: 20px 100px;
}
.claim {
  margin: 30px;
}
.choiceTextHidden {
  opacity: 0;
  transition: opacity 500ms;
}
.choices {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 300px;
}
.firstChoice,
.secondChoice {
  width: 500px;
  flex-shrink: 0;
  height: 250px;
}
.or {
  width: 200px;
  flex-shrink: 0;
}
.firstChoice,
.secondChoice,
.or {
  transition-property: opacity, width;
  transition-duration: 500ms, 500ms;
  transition-delay: 0s, 500ms;
}
@media (min-width: 1300px) {
  .left > .or,
  .left > .secondChoice,
  .right > .or,
  .right > .firstChoice  {
    opacity: 0;
    pointer-events: none;
    width: 0;
  }
}

@media (max-width: 1300px) {
  .choices {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: auto;
  }
  .firstChoice,
  .secondChoice,
  .or {
    transition-property: opacity, height;
    transition-duration: 500ms,  500ms;
    transition-delay: 0s, 500ms;
  }
  .left > .or,
  .left > .secondChoice,
  .right > .or,
  .right > .firstChoice  {
    opacity: 0;
    pointer-events: none;
    height: 0;
  }
}
