body {
  margin: 0;
  font-family: sans-serif;
  color: rgba(88, 88, 88, 1);
}
a {
  color: inherit;
}
html {
  height: 100%;
}
body {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}
